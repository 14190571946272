import Pusher from 'pusher-js';

let pusher: Pusher | null = null;

// Function to generate a Pusher channel name based on ID and type.
const makePusherChannelName = (id: string, type: string): string => {
  switch (type) {
    case null:
      return id;
    case 'escalation':
    case 'level':
    case 'lvl':
    case 'esc':
      return `level-${id}`;
    case 'hotel':
      return `hotel-${id}`;
    case 'organization':
    case 'company':
    case 'org':
      return `org-${id}`;
    case 'department':
    case 'desk':
    case 'dept':
      return `desk-${id}`;
    case 'user':
    case 'users':
      return `user-${id}`;
    default:
      return ''; // Default to an empty string if the type is unrecognized
  }
};

// Function to determine the environment based on the current URL.
function getEnv() {
  var curURL = window.location.href;
  var env = "production";
  if((curURL.indexOf('localhost')!== -1) || (curURL.indexOf('.dj')!== -1) || (curURL.indexOf('127.0.0.1')!== -1)) {
      env = "local";
  }
  else if((curURL.indexOf('staging.')!== -1) || (curURL.indexOf('stage.')!== -1)) {
      env = "staging";
  }
  return env;
}

// Function to get the Pusher App Key based on the environment.
function getPusherAppKey() {
  const env = getEnv();
  switch (env) {
      case "production":
          return "39d460e5117b1f00a378";
      case "staging":
          return "4f6012fc8337d82197b7";
      case "dev":
      case "local":
          return "35ff94e79bf9105bc995";
      default:
          throw new Error(`Unknown environment: ${env}`);
  }
}

// Initialize Pusher instance if not already initialized.
const initPusher = () => {
  if (!pusher) {
    try {
      pusher = new Pusher(getPusherAppKey(), {
        cluster: 'ap2',
        disableStats: true,
        authEndpoint: 'api/v1/pusher/auth', // Authentication endpoint for Pusher
      });
      console.log("Pusher initialized successfully");
    } catch (error) {
      console.error("Pusher initialization failed:", error);
    }
  }
  return pusher;
};

// Subscribe to Pusher channels based on user data.
export const subscribeToChannels = (userData: any) => {
  if (!userData) return;

  const channelIds: string[] = [];

  // Add global and specific channels
  channelIds.push('CheckInn');
  const userChannel = makePusherChannelName(userData.uid, 'user');
  const hotelChannel = makePusherChannelName(userData.hotel.uid, 'hotel');

  if (userChannel) channelIds.push(userChannel);
  if (hotelChannel) channelIds.push(hotelChannel);

  // Subscribe to notification desks
  if (userData.notifications) {
    userData.notifications.forEach((nDesk: any) => {
      const deskChannel = makePusherChannelName(nDesk.uid, 'desk');
      if (deskChannel) channelIds.push(deskChannel);
    });
  }

  // Subscribe to escalation levels
  if (userData.escalation_levels) {
    userData.escalation_levels.forEach((elvl: any) => {
      const levelChannel = makePusherChannelName(elvl.uid, 'level');
      if (levelChannel) channelIds.push(levelChannel);
    });
  }

  // Subscribe to all channels
  const pusherInstance = initPusher();
  channelIds.forEach((channel) => {
    if (channel) {
      const pusherChannel = pusherInstance?.subscribe(channel);
      pusherChannel?.bind('pusher:subscription_succeeded', () => {
        console.log(`Pusher: Subscribed to channel :: ${channel}`);
      });
      pusherChannel?.bind('pusher:subscription_error', (status: any) => {
        console.error(`Pusher: FAILED to Subscribe :: ${channel}`, status);
      });
    }
  });
};

// Bind a Pusher event to the callback function.
export const bindEvent = (event: string, callback: Function) => {
  const pusherInstance = initPusher();
  pusherInstance?.bind(event, callback);
};

// Unbind all Pusher events and disconnect the instance.
export const unbindAll = () => {
  if (pusher) {
    pusher.disconnect();
    pusher = null;
  }
};
