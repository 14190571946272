import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { subscribeToChannels, bindEvent, unbindAll } from './PusherService';
import useAuth from 'src/hooks/useAuth';
import { axiosInstance as axios  } from 'src/utils/axios';
import { useRequests } from 'src/contexts/RequestsContext';

const PusherEvents = () => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // Local state for server delta
  const [serverDelta, setServerDelta] = useState<string | null>(null);
  const { filters, setRequests } = useRequests(); // Access the shared requests state

// GUID generation function
  const guid = () => {
    const _p8 = (s: boolean) => {
      const p = (Math.random().toString(16) + '000000000').substr(2, 8);
      return s ? `-${p.substr(0, 4)}-${p.substr(4, 4)}` : p;
    };
    return `${_p8(false)}${_p8(true)}${_p8(true)}${_p8(false)}`;
  };

  useEffect(() => {
    if (!user) return;

    // Subscribe to channels
    subscribeToChannels(user);

    // Bind events
    bindEvent('new-request', (data: any) => {
      console.log('[PUSHER: new-request] Server says:', data.message);
      const newGuid = guid();
      setServerDelta(newGuid); // Set the GUID as the delta
      enqueueSnackbar(data.message || 'A new request has been created.', { variant: 'info' });
    });

    bindEvent('request-status-update', (data: any) => {
      console.log('[PUSHER: request-status-update] Server says:', data.message);
      const newGuid = guid();
      setServerDelta(newGuid); // Set the GUID as the delta
      enqueueSnackbar(data.message || "A request's status has been updated.", { variant: 'info' });
    });

    return () => {
      unbindAll();
    };
  }, [user, enqueueSnackbar]);

  // Effect to handle changes to serverDelta
  useEffect(() => {
    if (!serverDelta) return;

    console.log(`serverDelta updated -- id: ${serverDelta}`);

    // Example API call to refresh data based on serverDelta
    // const fetchRequests = async () => {
    //   try {
    //     const response = await axios.get(`hotel/${user?.hotel_id}/desk/0/requests/All`);

    //     console.log('serverDelta triggered -- Refreshing Requests List');
    //     setRequests(response.data || []);
    //   } catch (error) {
    //     console.error('Failed to fetch requests:', error);
    //   }
    // };
    const fetchRequests = async () => {
      try {
        const params = {
          ...filters, // Include current filters in the API call
          page: filters?.page || 1,
          per_page: filters?.per_page || 50,
        };
  
        const response = await axios.get(`hotel/${user?.hotel_id}/desk/0/requests/all`, { params });
        console.log('serverDelta triggered -- Refreshing Requests List');
        setRequests(response.data.data || []);
      } catch (error) {
        console.error('Failed to fetch requests:', error);
      }
    };

    fetchRequests();
  }, [serverDelta, user]);

  return null; // This component doesn't render UI
};


export default PusherEvents;
