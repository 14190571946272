import React, { createContext, useState, useContext } from 'react';

interface Request {
  id: string;
  message: string;
  status: string;
  [key: string]: any; // Add other fields as needed
}

interface RequestsContextType {
  requests: Request[];
  setRequests: React.Dispatch<React.SetStateAction<Request[]>>;
  filters: any; 
  setFilters: React.Dispatch<React.SetStateAction<any>>;
}

const RequestsContext = createContext<RequestsContextType | undefined>(undefined);

export const RequestsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [requests, setRequests] = useState<Request[]>([]);
  const [filters, setFilters] = useState<any>(null); // Initialize filters as needed

  return (
    <RequestsContext.Provider value={{ requests, setRequests,  filters, setFilters  }}>
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequests = () => {
  const context = useContext(RequestsContext);
  if (!context) {
    throw new Error('useRequests must be used within a RequestsProvider');
  }
  return context;
};
