import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Avatar, Box, ListItem } from '@mui/material';
import { axiosInstance as axios } from '../../utils/axios';

//  Models: users/items/orgs=>hotels/desks
// <SearchAndSelect type="single"/"multiple" model="users" outputType="id"/"object" handleSelect={callback} defaultValue={} disabled />

const useDebounce = (value: any, delay = 500) => {
	const [debouncedValue, setDebouncedValue] = useState(value)
	useEffect(() => {
		const id = setTimeout(() => {
			setDebouncedValue(value)
		}, delay);
		return () => {
			clearTimeout(id);
		}
	}, [value, delay]);

	return debouncedValue;
}

export default function SearchAndSelect({ model, handleSelect, outputType, type, defaultValue, disabled, dropdownOptions, label, selectedOption}: any) {

	const [options, setOptions] = useState<any>(dropdownOptions);
	const [searchVal, setSearchVal] = useState('');

	const [selectedValue, setSelectedValue] = useState(defaultValue ? defaultValue : null)

	const debouncedValue = useDebounce(searchVal, 750);

	const getData = async (searchVal: any) => {

		if(model==="importer") {
			setOptions([
				// match importerKey with ImporterController.php cases; also @todo: Move JSON to config on server side for single-source of truth;
				{ id: 1, product:"Vesta", name: "Org Locations", importerKey:"hotel_locations" },
				{ id: 2, product:"Vesta", name: "Request Items", importerKey:"request-items" },
				{ id: 3, product:"Vesta", name: "Requests (One Direct)", importerKey:"requests-oneDirect" },
				{ id: 4, product:"Vesta", name: "Users", importerKey:"users" },
			])
			return;
		}

		let apiUrl = `/uberSearch?cache=true&on=${model}&searchTerm=${searchVal}${
			selectedOption ? `&hotel_id=${selectedOption.id}` : ""
		}`;
		axios.get(apiUrl).then((res) => {
			console.log(`${model}`, res.data);
			setOptions(res.data);
		});
	};
	useEffect(() => {
		if(selectedOption){
			getData(searchVal).then();
		}
	}, [selectedOption]);

	const handleDataSelect = (data: any, type: any, outputType: any) => {
		if (type === 'single') {
			if (outputType === 'object') {
				handleSelect(data);
			} else if (outputType === 'id') {
				handleSelect(data.id);
			}
		} else if (type === 'multiple') {
			if (outputType === 'object') {
				handleSelect(data);
			} else if (outputType === 'id') {
				let arrIds: any = [];
				data.forEach((item: any) => {
					arrIds.push(item.id);
				});
				handleSelect(arrIds);
			}
		}
		setSelectedValue(data)
	};

	useEffect(() => {
		if(dropdownOptions && dropdownOptions.length>0) return; // If options were passed, no need to fetch data again
		getData(debouncedValue).then(r=>{});
	}, [debouncedValue]);
	useEffect(() => {
		if(dropdownOptions && dropdownOptions.length>0) {
			setOptions(dropdownOptions);
		}
	}, [dropdownOptions]);

	const onInputChange = (e: any, val: any) => {
		setSearchVal(val);
	}

	const RenderCustomOption=(option:any)=>{
		// console.log(option)
		if(model==="users"){
			return(
				<>
				<Avatar src={option.avatar} />
				<Box sx={{ ml:1 }}>
				<Box>{option.name}</Box>
				<Box sx={{fontSize:"small", color:"grey"}}>{option?.username} &#183; {option?.email}</Box>
				</Box>
				</>
			)
		}
		else if(model==="items"){
			return(
			<>
			<Box sx={{ml:1}}>
			<Box>{option.name}</Box>
			<Box sx={{fontSize:"small", color:"grey"}}>ID: {option.id}</Box>
			</Box>
			</>
			)
		}
		else if(model==="hotels") {
			return(
			<>
			<Box sx={{ml:1}}>
			<Box>{option.name}</Box>
			<Box sx={{fontSize:"small", color:"grey"}}>{option.location} &#183; ID: {option.id}</Box>
			</Box>
			</>
			)
		}
		else if(model==="desks") {
			return(
			<>
			<Box sx={{ml:1}}>
			<Box>{option.name}</Box>
			<Box sx={{fontSize:"small", color:"grey"}}>ID: {option.id} &#183; Org ID: {option.hotel_id}</Box>
			</Box>
			</>
			)
		}
		else return (
			<>
				<Box sx={{ml:1}}>
					<Box>{option.name}</Box>
					<Box sx={{fontSize:"small", color:"grey"}}>
						{ option?.product && <>Product: {option.product??''}</> }
					</Box>
				</Box>
			</>
			)

	}

	return (
		<Box>
			{options ? (
					<Autocomplete
						multiple={type === 'multiple'}
						options={options}
						filterOptions={(options, state) => options}
						value={selectedValue}
						disabled={disabled ? disabled : false}
						isOptionEqualToValue={(option, value) => +option.id === +value.id}
						getOptionLabel={(option: any) => (option.name)}
						onChange={(e, val) => handleDataSelect(val, type, outputType)}
						onInputChange={onInputChange}
						renderOption={(props, option: any) => (
							<ListItem {...props} sx={{width:"100%"}}>
								<RenderCustomOption {...option}/>
							</ListItem>
						  )}
						renderInput={(params) => (
							<TextField {...params} variant="standard" label={label??`Select`}/>
						)}
						//freeSolo={!selectedValue}
						//disableClearable={!!selectedValue}
					/>
				) :
				<></>
			}
		</Box>
	);
}
